<template>
    <div v-if="selectedDocumentsId.length > 0">
        <p>
            Documents To Link: 
            <span v-for="(document, index) in selectedDocumentsId" :key="index">
                <span v-if="index === selectedDocumentsId.length - 1">
                    <strong>
                        {{ document.title }}
                    </strong>.
                </span>
                <span v-else>
                    <strong>
                        {{ document.title }}
                    </strong>,
                </span>
            </span>
        </p>
    </div>

    <DataTable 
        :value="documentsFilteredByCountriesReduced" 
        :loading="loadingDocuments" 
        responsiveLayout="scroll" 
        showGridlines
        stripedRows

        v-model:filters="filters /*filters*/"  
        filterDisplay="menu"



        paginator 
        :rows="20" 
        :rowsPerPageOptions="[20, 50]" 
    >
        <template #header>
            <div class="table-header row gy-3 d-flex flex-row justify-content-between">
                <div class="col-sm-12 col-md-4 d-flex flex-row justify-content-md-start">
                    <!-- <Button 
                    type="button" 
                    icon="pi pi-plus" 
                    label="Add Document" 
                    class="p-button-outlined"
                    @click="openAddDocument()" 
                    /> -->
                    <h5 style="margin-bottom: 0;">Documents</h5>
                </div>

                <!-- Clear Button, FilterApplication and keywordSearch  -->
                <div class="col-sm-12 col-md-8">
                    <div class="d-flex flex-row justify-content-md-end">
                        <!-- <Button 
                            type="button" 
                            icon="pi pi-filter-slash" 
                            label="Clear" 
                            class="p-button-outlined me-4"
                            @click="clearFilter()" 
                        /> -->

                        <!-- DocumentTypeFilter -->
                        <!-- <Dropdown
                            v-model="filterDocumentSourceType"
                            :options="documentSources"
                            optionLabel="name"
                            optionValue="documentSourceId"
                            placeholder="Filter Document Type"
                            :showClear="true"
                            class="me-4"
                            style="width:25%;"
                        >
                        </Dropdown> -->

                        <Dropdown 
                            class="me-3"
                            v-model="this.country" 
                            :options="this.countriesOptions" 
                            optionLabel="name"
                            optionValue="countryId" 
                            :filter="true" 
                            placeholder="Select Country"
                            showClear 
                            style="width: 10rem;"
                        />


                        <Dropdown 
                            class="me-3"
                            v-model="manufacturerId"
                            :options="companies"
                            optionLabel="name"
                            optionValue="companyId"
                            :filter="true"
                            placeholder="Select Manufacturer"
                            showClear 
                            style="width: 10rem;"
                        />


                        <span class="p-input-icon-left" style="position: relative;">
                            <i class="pi pi-search" style="position: absolute; left: 5%; top:50%"  />
                            <InputText 
                                v-model="filters['global'].value" 
                                placeholder="Keyword Search" 
                                class="keywordSearch"
                            />
                        </span>
                    </div>
                </div>
            </div>
        </template>
        <Column 
            field="title" 
            header="Title"  
            style="width: 45%"
        /> 
        <Column 
            :field="'dateAdded'" 
            :header="'Date Added'" 
            :key="'dateAdded'" 
            style="width: 15%"
            bodyStyle="text-align: center"
        >
            <template #body="slotProps">
                {{ formatDate(slotProps.data.dateAdded) }}
            </template>
        </Column>
        <Column 
            :field="'documentDate'" 
            :header="'Document Date'" 
            :key="'documentDate'" 
            style="width: 15%"
            bodyStyle="text-align: center"
        >
            <template #body="slotProps">
                {{ formatDate(slotProps.data.documentDate) }}
            </template>
        </Column>
        <Column 
            bodyStyle="text-align: center"
        >
            <template #body="slotProps">
                <Checkbox 
                    v-model="selectedDocumentsId"
                    :value="
                        {
                            documentId:slotProps.data.documentId,
                            title:slotProps.data.title,
                        }
                    "
                />
            <!-- v-model="createSWOTItemForm.global" -->
            </template>
        </Column>
    </DataTable>
</template>
<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
// import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import formatToGlobalDate from '../../../../../../shared/helpers/date.helper';

export default {
    name: 'ProgramOverviewDocumentFilteredDataTable',
    components:{
        DataTable, 
        Column,
        Checkbox,
        InputText,
        Dropdown,
        // Button
    },
    emits: ['setDocumentsForLinkProgram'],
    props: ['companyId','documentsFilteredByCountries','companies','countryId','countriesOptions'],
    data(){
        return {
            loadingDocuments:true,
            documentsFilteredByCountriesReduced: null,
            selectedDocumentsId: [],

            // filters
            filters:null,

            // manufacturerId
            manufacturerId:null,

            // countryId
            country:null
        }
    },
    created() {
        this.initFilters();        
    },
    async mounted(){
        this.loadingDocuments = false
        // this.documentsFilteredByCountriesReduced=this.documentsFilteredByCountries.slice(0, 100);
        // this.documentsFilteredByCountriesReduced= this.documentsFilteredByCountries;
        
        this.manufacturerId = this.companyId // watched Variable
        this.country = this.countryId
    },
    computed:{

    },
    methods: {
        formatDate(date) {
            return formatToGlobalDate(date);
        },

        initFilters() {
            this.filters = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                name: {
                operator: FilterOperator.AND,
                constraints: [
                    { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                ],
                },
            };
        },
        clearFilter() {
          this.initFilters();
        },

        filterDocumentsFilteredByCountriesReduced(){
            this.loadingDocuments = true;
            const deepClonedDocumentsFilteredByCountries = JSON.parse(JSON.stringify(this.documentsFilteredByCountries));

            if(this.manufacturerId){
                if(this.country){
                    this.documentsFilteredByCountriesReduced = deepClonedDocumentsFilteredByCountries.filter(document => 
                        document.documentCompanies.some(company => company.companyId === this.manufacturerId) && 
                        document.documentCountries.some(country => country.countryId === this.country) 
                    );
                }
                else{
                    this.documentsFilteredByCountriesReduced = deepClonedDocumentsFilteredByCountries.filter(document => 
                        document.documentCompanies.some(company => company.companyId === this.manufacturerId)
                    );
                }
            }
            else{
                if(this.country){
                    this.documentsFilteredByCountriesReduced = deepClonedDocumentsFilteredByCountries.filter(document => 
                        document.documentCountries.some(country => country.countryId === this.country) 
                    );
                }
                else{
                    this.documentsFilteredByCountriesReduced = this.documentsFilteredByCountries;
                    // this.documentsFilteredByCountriesReduced === deepClonedDocumentsFilteredByCountries    
                }
            }
            this.loadingDocuments = false;
        },

        setSelectedDocumentsId(){
            this.$emit("setDocumentsForLinkProgram",{documentsList: this.selectedDocumentsId});
        },
    },
    watch:{
        manufacturerId() {
            this.filterDocumentsFilteredByCountriesReduced()
        },
        country(){
            this.filterDocumentsFilteredByCountriesReduced()
        },
        selectedDocumentsId(){
            this.setSelectedDocumentsId()
        }
    },
}
</script>
<style scoped>
.keywordSearch{
   /* padding: 0 0 0 2.5rem  !important; */
   padding: 0.2rem 0 0.2rem 2.5rem  !important;
}

.filterDropdown{
   /* padding: 0 0 0 2.5rem  !important; */
   padding: 0 0.2rem 0 0  !important;
}
    
</style>    